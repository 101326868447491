<template>
  <section class="create-content-wrapper">
    <div id="scene-container-content" ref="sceneContainerContent"></div>
    <div class="container-fluid d-flex ">
        <div class="w-100">
            <div class="container-content ml-auto">
                <div class="mr-5 pr-5">
                    <div class="konten-text mr-5">
                        <div class="container-md px-5 py-5 text-left">
                            <h1 v-if="userInfo.name != null || userInfo.name != ''" class="font-weight-normal mb-2">Halo, <span class="font-weight-bold">{{userInfo.name}}</span></h1>
                            <h1 v-else class="font-weight-normal mb-2">Halo, <span class="font-weight-bold">Sovia</span></h1>
                            <h3 class="font-weight-normal">Ingin buat konten apa hari ini?</h3>
                        </div>
                    </div>
                    <div class="d-flex mt-4 mb-4">
                        <div type="button" @click="chooseType('quiz')" class="card-konten mr-4 d-flex align-items-center justify-content-center" :class="quiz == true ? 'quiz-aktif' : 'quiz' ">
                          <div>
                            <img src="@/assets/images/logo/new-quiz.svg" alt="" class="svg-tipe">
                            <h3 class="font-weight-normal mt-2">Quiz</h3>
                          </div>
                        </div>
                        <!-- <div type="button" @click="chooseType('polling')" class="card-konten mr-4 d-flex align-items-center justify-content-center" :class="polling == true ? 'quiz-aktif' : 'quiz' ">
                          <div>
                            <img src="@/assets/images/logo/new-polling.svg" alt="" class="svg-tipe">
                            <h3 class="font-weight-normal mt-2">Polling</h3>
                          </div>
                        </div> -->
                        <div type="button" style="cursor : not-allowed" class="card-konten card-konten-mvp mr-4 d-flex align-items-center justify-content-center">
                          <div>
                            <img src="@/assets/images/logo/new-polling.svg" alt="" class="svg-tipe">
                            <h3 class="font-weight-normal mt-2">Polling</h3>
                            <p class="mb-0 text-muted mt-2">Coming Soon</p>
                          </div>
                        </div>
                        <div type="button" style="cursor : not-allowed" class="card-konten card-konten-mvp d-flex align-items-center justify-content-center">
                          <div>
                            <img src="@/assets/images/logo/new-training.svg" alt="" class="svg-tipe">
                            <h3 class="font-weight-normal mt-2">Training</h3>
                            <p class="mb-0 text-muted mt-2">Coming Soon</p>
                          </div>
                        </div>
                    </div>
                    <div class="bg-none mt-5 mr-5">
                      <p class="text-left px-3 py-3">{{ copyWriting }}</p>
                    </div>
                    <div class="mr-5 mb-5">
                        <button v-if="quiz != false || polling != false || training != false" class="btn btn-primary btn-lg btn-block" @click="addQuiz">LANJUTKAN</button>
                        <button v-else class="btn btn-primary btn-lg btn-block disabled">LANJUTKAN</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>
<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Stats from "stats.js";
import Swal from "sweetalert2";
// import { addQuestions } from '@/services/question/question.service'
import {postContent} from '@/services/quiz-creator/quiz-creator.service'

import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import $ from "jquery";

export default {
  data() {
    return {
      container: null,
      scene: null,
      camera: null,
      controls: null,
      renderer: null,
      stats: null,
      mixers: [],
      clock: null,
      additional : false,
      listQuestion:[],
      quiz : true,
      polling : false,
      training : false,
      userInfo : [],
      copyWriting : 'Yuk, tes kemampuan dan pemahaman lewat kuis interaktif dan mainkan bersama teman-teman.',
      formData: {
        grade_id: null,
        category_id: null,
        learning_category_id: null,
        quiz_name: null,
      },
      
    };
  },
  methods: {
    init() {
      // set container
      this.container = this.$refs.sceneContainerContent;

      // add stats
      this.stats = new Stats();
      this.clock = new THREE.Clock();
      this.container.appendChild(this.stats.dom);

      // add camera
      const fov = 5.5; // Field of view
      const aspect = this.container.clientWidth / this.container.clientHeight;
      const near = 0.1; // the near clipping plane
      const far = 1000; // the far clipping plane
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 2, 12);
      this.camera = camera;

      // create scene
      this.scene = new THREE.Scene();

      // add lights
      const ambientLight = new THREE.HemisphereLight(
        0xffffff, // bright sky color
        0x222222, // dim ground color
        1 // intensity
      );
      const mainLight = new THREE.DirectionalLight(0xffffff, 4.0);
      mainLight.position.set(10, 10, 10);
      this.scene.add(ambientLight, mainLight);

      // add controls
      this.controls = new OrbitControls(this.camera, this.container);
      // this.controls.enabled = false
      this.controls.minPolarAngle = this.controls.maxPolarAngle = Math.PI * 0.45;
      this.controls.enableZoom = false; //To enable zoom mode on 3d

      // create renderer
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.physicallyCorrectLights = true;
      this.container.appendChild(this.renderer.domElement);

      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect =
        this.container.clientWidth / this.container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );

      //load model

      const loader = new GLTFLoader();

      const onLoad = (result, position) => {
        const model = result.scene.children[0];
        model.position.copy(position);
        model.scale.set(0.5, 0.5, 0.5);

        const mixer = new THREE.AnimationMixer(model);
        this.mixers.push(mixer);

        const animation = result.animations[0];
        const action = mixer.clipAction(animation);
        action.play();

        this.scene.add(model);
      };

      const parrotPosition = new THREE.Vector3(0, -0.5, 0);
      loader.load(
        // "/three-assets/RobotExpressive.glb",
        "/three-assets/scene.glb",
        (gltf) => onLoad(gltf, parrotPosition),
        undefined,
        undefined
      );

      this.renderer.setAnimationLoop(() => {
        this.update();
        this.render();
      });
    },
    update() {
      const delta = this.clock.getDelta();
      this.mixers.forEach((mixer) => mixer.update(delta));
    },
    render() {
      this.renderer.render(this.scene, this.camera);
    },
    setSideBar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },
    chooseType(type){
      if(type == 'quiz'){
        this.quiz = true;
        this.polling = false;
        this.training = false;
        this.copyWriting = 'Yuk, tes kemampuan dan pemahaman lewat kuis interaktif dan mainkan bersama teman-teman.'
      } else if(type == 'polling'){
        this.quiz = false;
        this.polling = true;
        this.training = false;
        this.copyWriting = 'Bingung menentukan mana yang terbaik? Kamu bisa undang teman sebanyak-banyaknya buat ikutan polling. Dijamin lebih cepat!'
      } else {
        this.quiz = false;
        this.polling = false;
        this.training = true;
        this.copyWriting = 'Ingin suasana belajar lebih interaktif dan seru? Saatnya buat video yang terlihat menarik dan berwarna!'
      }
      
    },
    addQuiz(){
      localStorage.removeItem("listQuestionCreator");
      localStorage.removeItem("newListQuestionCreator")
      localStorage.removeItem("questionNew")
      localStorage.removeItem("listQuestion")
      localStorage.removeItem("listQuestionCreate")
      localStorage.removeItem("listQuestionCreatePolling")
      localStorage.removeItem("backUpPollingQuestion")
      localStorage.removeItem("backUpCreateQuestion")
      localStorage.removeItem("backUpUpdateQuestion")
      localStorage.removeItem("quiz_name")
      localStorage.removeItem("quiz_description")
      localStorage.removeItem("images_cover")
      localStorage.removeItem("category_id")
      localStorage.removeItem("createTag")
      localStorage.removeItem("quiz_name_polling")
      localStorage.removeItem("quiz_description_polling")
      localStorage.removeItem("images_cover_polling")
      localStorage.removeItem("category_id_polling")
      
      this.$store.state.dataQuiz.category_id = null,
      this.$store.state.dataQuiz.quiz_name = null,
      this.$store.state.dataQuiz.description = null,
      this.$store.state.dataQuiz.images_cover = null

      let formData = {
        content_type : null
      };

      if(this.quiz == true){
        formData.content_type = 'quiz'
      } else if (this.polling == true){
        formData.content_type = 'polling'
      } else if (this.training == true){
        formData.content_type = 'training'
      }

      postContent(formData)
      .then((response) =>{
        if(response.status && response.status == true){
          if(this.quiz == true){
            this.$router.push({ name: 'Create-My-Quiz'})
          } else if (this.polling == true){
            this.$router.push({ name: 'Setup-Polling'})
          }
        }
      })
    },
    alertForQuiz(){
      Swal.fire('Mohon Maaf', 'Untuk saat ini tipe quiz tersebut belum tersedia')
    },
  },
  computed: {
    hasAdditional(){
      console.log(this.opsi1)
        return this.opsi1.length >0;
    }
  },
  mounted() {
    $("section.create-content-wrapper").parent().addClass("m-0");

    this.setSideBar();
    this.init();
    let data = localStorage.getItem("listQuestion");
    this.listQuestion = JSON.parse(data);

    let users = localStorage.getItem("users")
    this.userInfo =JSON.parse(users)
  },
};
</script>

<style scoped>
.dot-quiz{
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  display: inline-block;
  background-color: #BC3BF2;
}

.dot-polling{
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  display: inline-block;
  background-color: #FDBA59;
}

.dot-training{
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  display: inline-block;
  background-color: #EB5757;
}

.card-konten{
  height: 30vh;
  width: 12vw;
  background-color: #fff;
  border-radius: 30px;
}

.card-konten-mvp{
  background-color: #B6B6B6 !important;
}

.quiz:hover{
  border: 3px solid #7280FF;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,219,255,1) 100%);
  transform: scale(1.1);
}

.polling:hover{
  border: 3px solid #019D10;
  transform: scale(1.1);
}

.training:hover{
  border: 3px solid #FDBA59;
  transform: scale(1.1);
}

.quiz-aktif{
  border: 3px solid #7280FF;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(215,219,255,1) 100%);
  transform: scale(1.1);
}

.polling-aktif{
  transform: scale(1.1);
  border: 3px solid #019D10;
}

.training-aktif{
  transform: scale(1.1);
  border: 3px solid #FDBA59;
}

.bg-none{
  background-color:#EDEEF1;
  border-radius:6px;
}
.svg-tipe{
  width : 72px;
  height : 72px;
}
</style>

<style>
html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
.main-wrapper {
  height: 100% !important;
}
.main-container-fluid {
  /* height: 100% !important;
  margin-left: 0px !important;
  padding: 0 !important; */
}
.create-content-wrapper {
  height: 100%;
  width: 100%;
  background-size: cover;
}
.container-content{
    padding-top:20vh ;
    left: 30vw;
    width: 50%;
    margin-right: 35rem;
    z-index: 3;
    position: relative;
}
#scene-container-content {
  width: 100%;
  height: 114.8%;
  position: absolute;
  left: -25vw;
  /* top: 5vh; */
  overflow: hidden !important;
}
#scene-container-content div {
  display: none !important;
}
/* 
#scene-container-content canvas{
  height: 150% !important;
  width: 100% !important;
} */

.container-filter {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}
.container-question {
  width: 67.5%;
}
.konten-text{
    background: #E5E5E5;
    border-radius: 20px;
}

.konten-text:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -42px;
    top: 17px;
    /* border: 10px solid transparent;
        border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
    /* border-right: 32px solid rgba(193,193,193,0.5);  */
    z-index: 2;
}

.konten-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -45px;
  top: 180px;
  border: 15px solid transparent;
  border-right: 30px solid #E5E5E5;
  z-index: 3;
}

@media (max-width: 1367px){
  .konten-text{
    background: #E5E5E5;
    border-radius: 20px;
    width: 100%;
  }

  .konten-text:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -45px;
    top: 185px;
    border: 15px solid transparent;
    border-right: 30px solid #E5E5E5;
    z-index: 3;
  }
}


@media (max-width: 1113px){
  .konten-text:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -45px;
    top: 190px;
    border: 15px solid transparent;
    border-right: 30px solid #E5E5E5;
    z-index: 3;
  }
}

</style>
